















































































































































































































































































































































































































































































































@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.edit-field {
  > .segment-wrapper {
    row-gap: 2rem;
    column-gap: 4rem;
    display: flex;
    flex-wrap: wrap;
  }

  .segment {
    flex: 1;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      flex: 0 0 100%;
    }
  }

  .input-group-append .input-group-text {
    min-width: 60px;
  }

  .field-row-title {
    margin-top: 2rem;
  }

  .field-row-title:first-child {
    margin-top: 0;
  }

  // field label
  .col-12:has(>.field-row-label) {
    padding-right: 0;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      padding: 0 1rem;
    }
  }

  // field content
  .col-12:has(>.field-row-label)~.col-12 {
    padding-left: 1rem;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      padding: 0 1rem;
    }
  }

  .field-row-label {
    padding: calc(0.438rem + 1px) 0;
    margin-bottom: 0;
    font-weight: bold;
    opacity: 0.8;
    //text-align: right;
    //
    //@media (max-width: (map-get($grid-breakpoints, lg))) {
    //  text-align: left;
    //}
  }

  .field-row-item {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .vs__dropdown-toggle {
    white-space: nowrap;
  }
}
